<template lang="pug">
div(class="desc")
  div(class="mt-3 py-3 message")
    p(class="mb-0 fs-5 text-center success") {{ errorData.message }}
  div(class="mt-5 justify-content-center")
    a(
      href="https://docs.google.com/forms/d/1iScAWWwuDh-BFC-w4ABJFZXeh0_T4PdOlabN2kpPriI/viewform?edit_requested=true"
      target="_blank"
    )
      button(
        class="btn btn-main rounded-pill mx-1"
      ) 我想詢問問題
</template>
<script>

export default {
  name: 'Error',
  props: {
    errorData: {
      type: Object
    }
  }
}
</script>
