<template lang="pug">
div(class="app")
  div(class="header")
    img(
      class="clc-logo"
      src="../assets/logo.png"
    )
  div(
    v-if="!errorData"
    class="container py-5")
    div(v-if="step === 0")
      Step0(
        @error="handleError"
        @nextStep="handleNextStep"
        @stepOuter="handleToOuter")
    div(v-if="step === 'outer0'")
      StepOuter0(
        :identityType="identityType"
        @error="handleError"
        @nextStep="handleNextStep"
      )
    div(
      v-if="step === 1"
      class="my-4"
    )
      Step1(
        @error="handleError"
        @step2="identityType === 6 ? step = 'outer2' : step = 2")
    div(
      v-if="step === 2"
      class="my-4"
    )
      Step2(
        :formData="formData"
        :isHelper="isHelper"
        :identityType="identityType"
        :identityTypeText="identityTypeText"
        :serialNumberText="serialNumberText"
        @success="handleSuccessToSendForm"
        @error="handleError"
      )
    div(
      v-if="step === 'outer2'"
      class="my-4"
    )
      StepOuter2(
        :formData="formData"
        :outerData="outerData"
        :isHelper="isHelper"
        :identityType="identityType"
        :serialNumberText="serialNumberText"
        @success="handleSuccessToSendForm"
        @error="handleError"
      )
    div(
      v-if="step === 'finish'"
      class="my-4"
    )
      Finish(
        :identityType="identityType"
        :finishData="finishData"
        @back="step = 0"
      )
    div(
      v-if="step === 'privacy'"
      class="my-4"
    )
      Privacy(
        :privacySignHtml='formData.privacySignHtml'
        @agree="step = 1"
      )
  div(
    v-if="errorData"
    class="container py-5")
    Error(
      :errorData="errorData"
    )
</template>
<script>
import Step0 from "../components/Registration/step0";
import Step1 from "../components/Registration/step1";
import Step2 from "../components/Registration/step2";
import StepOuter0 from "../components/Registration/stepOuter0.vue";
import StepOuter2 from "../components/Registration/stepOuter2.vue";
import Privacy from "../components/Registration/pravicy.vue";
import Finish from "../components/Registration/finish";
import Error from "../components/Registration/error";

export default {
  name: "Registration",
  components: {
    Step0,
    Step1,
    Step2,
    StepOuter0,
    StepOuter2,
    Privacy,
    Finish,
    Error,
  },
  data() {
    return {
      isLoading: false,
      step: 0,
      finishData: {},
      errorData: null,
      privacy: "",
      formData: {},
      outerData: {},
      isHelper: false,
      identityType: null,
      identityTypeText: "",
      serialNumberText: "",
    };
  },
  methods: {
    handleNextStep(val) {
      this.formData = val.formData;
      this.isHelper = val.isHelper;
      this.identityType = val.identityType;
      this.identityTypeText = val.identityTypeText;
      this.serialNumberText = val.serialNumberText;
      if (val.outerData) {
        this.outerData = val.outerData;
      }
      if (val.formData.isShowPrivacySign) {
        this.step = "privacy";
      } else {
        this.step = 1;
      }
    },
    async handleToOuter(val) {
      this.identityType = val.identityType;
      this.serialNumberText = val.serialNumberText;
      if (val.identityType === 6) {
        this.step = "outer0";
      }
      if (val.identityType === 10) {
        this.step = "outer2";
      }
    },
    handleSuccessToSendForm(val) {
      this.step = "finish";
      this.finishData = val;
    },
    handleError(val) {
      this.errorData = val;
    },
    handleBack() {
      this.errorData = null;
    },
  },
};
</script>
