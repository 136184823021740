<template lang="pug">
ValidationObserver(v-slot="{ handleSubmit }")
  form(@submit.prevent="handleSubmit(sendFormData)")
    h4(class="my-3") {{ isHelper ? '請填寫幫別人報名的資料:' : '請填寫本人(自己)報名的資料:'}} 
    div(
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1 py-0") 
        p(class="mb-0") 序號:
      div(class="col-lg-4 col-md-8 col-10 py-0")
        p(class="mb-0 text-left") {{ serialNumberText }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1 py-0") 
        p(class="mb-0") 報名身分:
      div(class="col-lg-4 col-md-8 col-10 py-0")
        p(class="mb-0 text-left") {{ identityTypeText }}
    div(
      v-if="sendData.identityType !== 9 && sendData.identityType !== 3 && sendData.identityType !== 4"
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 
        span(class="text-danger") *
        span 組別
      div(class="col-lg-4 col-md-8 col-10")
        ValidationProvider(name="組別" rules="required" v-slot="{ errors }")
          select(
            v-model="groupType"
            class="form-select")
            option(value="" disabled) 請選擇您的組別
            option(
              v-for="(option, optionIndex) in groupTypeOptions" 
              :key="optionIndex"
              :value="option"
            ) {{ option }}
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      v-if="sendData.identityType !== 9 && sendData.identityType !== 3 && sendData.identityType !== 4"
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 
        span(class="text-danger") *
        span 牧區
      div(class="col-lg-4 col-md-8 col-10")
        ValidationProvider(name="牧區" rules="required" v-slot="{ errors }")
          select(
            v-model="districtName"
            class="form-select")
            option(value="" disabled) 請選擇您的牧區
            option(
              v-for="(option, optionIndex) in districtOptions" 
              :key="optionIndex"
              :value="option"
            ) {{ option }}
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      v-if="sendData.identityType !== 9 && sendData.identityType !== 3 && sendData.identityType !== 4"
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1")
        span(class="text-danger") *
        span 小組
      div(class="col-lg-4 col-md-8 col-10")
        ValidationProvider(name="小組" rules="required" v-slot="{ errors }")
          select(
            v-model="sendData.groupId"
            class="form-select")
            option(value="" disabled) 請選擇您的小組
            option(
              v-for="(option, optionIndex) in groupOptions" 
              :key="optionIndex"
              :value="option.groupId"
            ) {{ option.groupName }}
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 
        span(class="text-danger") *
        span 姓名
      div(class="col-lg-4 col-md-8 col-10")
        ValidationProvider(name="姓名" rules="required" v-slot="{ errors }")
          input(
            v-model="sendData.name"
            type="text"
            placeholder="請輸入您的姓名"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      v-if="sendData.identityType === 7"
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 
        span(class="text-danger") *
        span 介紹人
      div(class="col-lg-4 col-md-8 col-10")
        ValidationProvider(name="介紹人" rules="required" v-slot="{ errors }")
          input(
            v-model="sendData.introducer"
            type="text"
            placeholder="請輸入您的介紹人"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 
        span(class="text-danger") *
        span 手機
      div(class="col-lg-4 col-md-8 col-10")
        ValidationProvider(name="手機" rules="required|digits:10" v-slot="{ errors }")
          input(
            v-model="sendData.phone"
            type="text"
            placeholder="請輸入您的手機"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      v-if="sendData.identityType !== 7"
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 
        span Email
      div(class="col-lg-4 col-md-8 col-10")        
        input(
          v-model="sendData.email"
          type="text"
          placeholder="請輸入您的Email"
          class="form-control"
        ) 
    div(
      v-if="sendData.identityType === 7"
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 
        span(class="text-danger") *
        span Email
      div(class="col-lg-4 col-md-8 col-10")
        ValidationProvider(name="Email" rules="required" v-slot="{ errors }")
          input(
            v-model="sendData.email"
            type="text"
            placeholder="請輸入您的Email"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      legend(class="col-2 col-form-label pt-0 px-1") 
        span(class="text-danger") *
        span 性別
      div(class="col-lg-4 col-md-8 col-10")
        div(class="form-check form-check-inline float-start")
          input(
            v-model="sendData.gender"
            class="form-check-input"
            type="radio"
            value="男"
          )
          label(
            class="form-check-label"
          ) 男性
        div(class="form-check form-check-inline float-start")
          input(
            v-model="sendData.gender"
            class="form-check-input"
            type="radio"
            value="女"
          )
          label(
            class="form-check-label"
          ) 女性
        ValidationProvider(name="性別" rules="required" v-slot="{ errors }")
          input(
            v-model="sendData.gender"
            type="hidden"
            name="性別"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 
      div(class="col-lg-4 col-md-8 col-10")
        div(class="form-check form-check-inline float-start")
          input(v-model="isChristian" type="checkbox" id="christianText" name="已受洗" class="form-check-input")
          label(for="已受洗" class="form-check-label") 已受洗  
    div(
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 
      div(class="col-lg-4 col-md-8 col-10")
        div(class="form-check form-check-inline float-start")
          input(v-model="isMarriage" type="checkbox" id="marriageText"  name="已婚" class="form-check-input")
          label(for="已婚" class="form-check-label") 已婚   
    div(
      class="row mb-3 justify-content-center")
      label(class="col-2 col-form-label px-1") 備註
      div(class="col-lg-4 col-md-8 col-10")
        textarea(
          v-model="sendData.memo"
          type="text"
          class="form-control"
          maxlength="300"
        )
    div(class="justify-content-center")
      button(
        class="btn btn-main rounded-pill mx-1"
        type="submit"
      ) 送出報名
</template>
<script>
export default {
  name: "Step2",
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
    isHelper: {
      type: Boolean,
    },
    identityType: {
      type: Number,
    },
    identityTypeText: {
      type: String,
    },
    serialNumberText: {
      type: String,
    },
  },
  data() {
    return {
      groupTypeOptions: [],
      groups: [],
      groupType: "",
      districtName: "",
      maxDate: "",
      isChristian: null,
      isMarriage: null,
      sendData: {
        identityType: this.identityType,
        serialNumberText: this.serialNumberText,
        name: this.formData ? this.formData.name : "",
        introducer: this.formData
          ? this.formData.introducer
            ? this.formData.introducer
            : ""
          : "",
        phone: this.formData ? this.formData.phone : "",
        email: this.formData
          ? this.formData.email
            ? this.formData.email
            : ""
          : "",
        groupId: this.formData
          ? this.formData.groupId
            ? this.formData.groupId
            : ""
          : "",
        gender: this.formData ? this.formData.gender : "",
        memo: this.formData ? this.formData.memo : "",
        isHelper: this.isHelper,
        christianText: "",
        marriageText: "",
        isAgreePrivacy: true,
        token: "",
      },
    };
  },
  watch: {
    isChristian(val) {
      if (val) {
        this.sendData.christianText = "基督徒";
      } else {
        this.sendData.christianText = "";
      }
    },
    isMarriage(val) {
      if (val) {
        this.sendData.marriageText = "已婚";
      } else {
        this.sendData.marriageText = "單身";
      }
    },
  },
  computed: {
    districtOptions() {
      const groupMap = this.groups
        .filter((group) => group.group_type_name === this.groupType)
        .reduce((acc, g) => {
          acc[g.district_name] = true;
          return acc;
        }, {});
      return Object.keys(groupMap).map((g) => {
        return g;
      });
    },
    groupOptions() {
      return this.groups
        .filter((g) => g.district_name === this.districtName)
        .map((g) => ({ groupName: g.group_name, groupId: g.group_id }));
    },
  },
  methods: {
    async getClcGroupSelect() {
      try {
        let groupRes = await this.$api.main.getClcGroupSelect();
        this.groupTypeOptions = groupRes.content.group_type;
        this.groups = groupRes.content.group;
        if (this.formData) {
          const selectedGroup = groupRes.content.group.find((item) => {
            return item.group_id === this.formData.groupId;
          });
          this.groupType = selectedGroup ? selectedGroup.group_type_name : "";
          this.districtName = selectedGroup ? selectedGroup.district_name : "";
        }
      } catch (err) {
        console.log(err);
      }
    },
    async sendFormData() {
      try {
        let sendRes = await this.$api.main.sendRegistration(this.sendData);
        if (!this.sendData.isHelper) {
          document.cookie = `clc_form_info=${sendRes.content.clcFormInfo}`;
        }
        if (!sendRes.showalert && !sendRes.success) {
          this.$emit("error", sendRes);
        } else {
          this.$emit("success", sendRes.content);
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  created() {
    this.getClcGroupSelect();
    const today = new Date().toISOString().split("T")[0];
    this.maxDate = today;
    this.isChristian =
      this.formData && this.formData.christianText === "基督徒" ? true : false;
    this.isMarriage =
      this.formData && this.formData.marriageText === "已婚" ? true : false;
  },
};
</script>
