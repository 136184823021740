<template lang="pug">
div(class="desc")
  div(v-html="privacySignHtml")
  div(class="form-check agreeSign my-4 mx-auto")
    input(
      v-model="isAgreePrivacy"
      class="form-check-input"
      type="checkbox")
    label(class="form-check-label") 【←點選】我同意簽署
  div(class="justify-content-center")
    button(
      v-if="isAgreePrivacy"
      @click.prevent="agree"
      class="btn btn-main rounded-pill mx-1"
    ) 下一步
</template>
<script>
export default {
  name: "Privacy",
  props: {
    privacySignHtml: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isAgreePrivacy: false,
    };
  },
  methods: {
    agree() {
      this.$emit("agree");
    },
  },
};
</script>
