import axios from 'axios'
import { dev, prod } from './utils'

let instance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? prod : dev,
})

instance.interceptors.request.use((config) => {
  return config
}, (err) => {
  return Promise.reject(err)
})

instance.interceptors.response.use((response) => {
  return response.data
}, (err) => {
  const { response } = err
  if (response) {
    return Promise.reject(err)
  } else {
    if (!window.navigator.onLine) {
      alert('網路有問題, 重新整理試試')
    } else {
      return Promise.reject(err) 
    }
  }
})

export default (method, url, data=null) => {
  method = method.toLowerCase()
  switch (method) {
    case 'get': 
      return instance.get(url, { params: data })
    case 'post': 
      return instance.post(url, data)
    case 'put': 
      return instance.put(url, data)
    case 'patch': 
      return instance.patch(url, data)
    case 'delete': 
      return instance.delete(url, { params: data })
    default:
      console.log('unknown method')
  }
}