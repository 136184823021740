<template lang="pug">
div(class="page-info")
  button(
    @click.prevent="handleNextStep"
    class="btn btn-main btn-next rounded-pill mx-1"
  ) 下一步
  img(
      class="my-3"
      src="@/assets/1200-1200-banner.jpg"
      width="100%"
    )
</template>
<script>
export default {
  name: "Step1",
  components: {},
  methods: {
    handleNextStep() {
      this.$emit("step2");
    },
  },
};
</script>
<style scoped>
.page-info {
  position: relative;
}
.btn-next {
  position: sticky;
  top: 70px;
}
</style>
