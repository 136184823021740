<template lang="pug">
div(class="desc")
  h3(class="text-center") 2025 婚姻成長營
  div(class="py-3 message")
    p(class="mb-0 fs-5 text-center success") {{ finishData.titleDtl }} 報名成功
    //- p(v-if="identityType === 6" class="mb-0 fs-5 text-center success") 我們在 7 日內確認款項後
    //- p(v-if="identityType === 6" class="mb-0 fs-5 text-center success") 會寄發簡訊及 Email 通知您
    p(v-if="identityType === 6" class="mb-0 fs-5 text-center success") 謝謝
  div(class="mt-5 justify-content-center")
    button(
      class="btn btn-main rounded-pill mx-1"
      @click.prevent="handleBack"
    ) 回首頁
</template>
<script>
export default {
  name: "Finish",
  props: {
    finishData: {
      type: Object,
    },
    identityType: {
      type: Number,
    },
  },
  methods: {
    handleBack() {
      this.$emit("back");
    },
  },
};
</script>
