<template lang="pug">
ValidationObserver(v-slot="{ handleSubmit }")
  form(class="my-5" @submit.prevent="handleSubmit(checkOuterIfPay)")
    div(class="row mb-3 justify-content-center")
      label(class="col-lg-2 col-md-2 col-3 col-form-label px-1") 匯款帳號末5碼
      div(class="col-lg-4 col-md-8 col-9")
        ValidationProvider(name="匯款帳號" rules="required|digits:5" v-slot="{ errors }")
          input(
            v-model="outerData.account5"
            type="text"
            placeholder="請輸入匯款帳號"
            class="form-control"
          )
          span(class="d-block text-start fst-italic text-danger") {{ errors[0] }}
    div(
      class="row mb-3 justify-content-center")
      label(class="col-lg-2 col-md-2 col-3 col-form-label px-1") 匯款日期
      div(class="col-lg-4 col-md-8 col-9")
        input(
          v-model="outerData.remittanceDate"
          type="date"
          placeholder="請選擇匯款日期"
          class="form-control"
        )
    div(class="justify-content-center")
      button(
        class="btn btn-main rounded-pill mx-1"
        type="submit"
      ) 我確認有匯款了
</template>
<script>
export default {
  name: "StepOuter",
  props: {
    identityType: {
      type: Number,
    },
  },
  data() {
    return {
      outerData: {
        account5: "",
        remittanceDate: this.$dayjs().format("YYYY-MM-DD"),
      },
    };
  },
  methods: {
    async checkOuterIfPay() {
      try {
        let checkRes = await this.$api.main.getRegistrationOuter(
          this.outerData
        );
        if (checkRes.code === 400) {
          this.$emit("errorMessage", checkRes.message);
        }
        if (checkRes.code === 200) {
          this.$emit("nextStep", {
            outerData: this.outerData,
            formData: checkRes.content,
            identityType: this.identityType,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
