<template lang="pug">
div
  img(
      class="mt-4 mb-3"
      src="@/assets/1200-300-banner.jpg"
      width="100%"
    )
  form
    div(class="row mb-3 justify-content-center")
      label(class="col-lg-2 col-md-2 col-3 col-form-label px-1")
        span(class="require") * 
        span 我是要
      div(class="col-lg-4 col-md-8 col-9")
        select(
          v-model="isHelper"
          class="form-select")
          option(value="" disabled) 請選擇
          option(
            v-for="(option, optionIndex) in isHelperOptions" 
            :key="optionIndex"
            :value="option.value"
          ) {{ option.text }}
    div(class="row mb-3 justify-content-center")
      label(class="col-lg-2 col-md-2 col-3 col-form-label px-1") 
        span(class="require") * 
        span 報名身分
      div(class="col-lg-4 col-md-8 col-9")
        select(
          v-model="identityType"
          class="form-select")
          option(value="" disabled) 請選擇報名身份
          option(
            v-for="(option, optionIndex) in identityOptions" 
            :key="optionIndex"
            :value="option.value"
          ) {{ option.text }}
    div(
      v-if="showSerialNumberText"
      class="row mb-3 justify-content-center")
      label(class="col-lg-2 col-md-2 col-3 col-form-label px-1") 序號
      div(class="col-lg-4 col-md-8 col-9")
        input(
          v-model="SerialNumberText"
          type="text"
          placeholder="請輸入報名序號"
          class="form-control"
        )
    div(class="justify-content-center")
      button(
        v-if="showSerialNumberText"
        @click.prevent="checkSerialNumberText"
        class="btn btn-main rounded-pill mx-1"
      ) 下一步
      button(
        v-if="!showSerialNumberText"
        @click.prevent="noneSerialNumberText"
        class="btn btn-main rounded-pill mx-1"
      ) 下一步
</template>
<script>
export default {
  name: "Step0",
  components: {},
  data() {
    return {
      showSerialNumberText: true,
      identityOptions: [],
      isHelperOptions: [
        { text: "本人(自己)報名", value: false },
        { text: "幫別人報名", value: true },
      ],
      isHelper: "",
      identityType: "",
      identityTypeText: "",
      SerialNumberText: "",
    };
  },
  watch: {
    identityType(val) {
      if (val !== 6) {
        // 本堂會友
        this.showSerialNumberText = true;
      } 
      if (val === 6) {
        // 非本堂會友
        this.showSerialNumberText = false;
      }
      const currentIdentityType = this.identityOptions.find((item) => {
        return item.value === val;
      });
      this.identityTypeText = currentIdentityType.text;
    },
  },
  methods: {
    async getIdentityType() {
      try {
        let identityRes = await this.$api.main.getIdentityType();
        this.identityOptions = identityRes.content;
      } catch (err) {
        console.log(err);
      }
    },
    async checkSerialNumberText() {
      if (this.identityType !== "" && this.isHelper !== "") {
        try {
          let checkRes;

          if (this.identityType === 10) {
            checkRes = await this.$api.main.getRegistrationOuterOnSite({
              isHelper: this.isHelper,
              SerialNumberText: this.SerialNumberText,
            });
          }

          if (this.identityType !== 10) {
            checkRes = await this.$api.main.getRegistration({
              isHelper: this.isHelper,
              SerialNumberText: this.SerialNumberText,
            });
          }

          if (checkRes.code === 400) {
            this.$swal({
              icon: "error",
              text: checkRes.message,
              type: "error",
              showConfirmButton: "#ff0000",
            });
          }
          if (checkRes.code === 200) {
            if (this.identityType === 10) {
              this.$emit("stepOuter", {
                identityType: this.identityType,
                identityTypeText: this.identityTypeText,
                isHelper: this.isHelper,
                serialNumberText: this.SerialNumberText,
                formData: checkRes.content,
              });
            }
            if (this.identityType !== 10) {
              this.$emit("nextStep", {
                identityType: this.identityType,
                identityTypeText: this.identityTypeText,
                isHelper: this.isHelper,
                serialNumberText: this.SerialNumberText,
                formData: checkRes.content,
              });
            }
            
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        if (!this.identityType || this.identityType === "") {
          this.$swal({
            icon: "warning",
            text: "請選擇報名身份",
            type: "error",
            showConfirmButton: "#008087",
          });
        }
        if (this.isHelper === "") {
          this.$swal({
            icon: "warning",
            text: "請選擇自己報名或幫別人報名",
            type: "error",
            showConfirmButton: "#008087",
          });
        }
      }
    },
    noneSerialNumberText() {
      if (this.identityType !== "" && this.isHelper !== "") {
        this.$emit("stepOuter", {
          identityType: this.identityType,
        });
      } else {
        if (!this.identityType || this.identityType === "") {
          this.$swal({
            icon: "warning",
            text: "請選擇報名身份",
            type: "error",
            showConfirmButton: "#008087",
          });
        }
        if (this.isHelper === "") {
          this.$swal({
            icon: "warning",
            text: "請選擇自己報名或幫別人報名",
            type: "error",
            showConfirmButton: "#008087",
          });
        }
      }
    },
  },
  created() {
    this.getIdentityType();
  },
};
</script>
