import BaseAPI from "./base";

const mainAPI = {
  // 共用
  getIdentityType(data) {
    return BaseAPI("get", `/ClcForm/GetIdentityType`, data);
  },
  getClcGroupSelect(data) {
    return BaseAPI("get", `/ClcForm/SelectGroup`, data);
  },

  // 報名
  getRegistration(data) {
    return BaseAPI("post", `/Registration/ReadRegistration`, data);
  },
  getRegistrationOuter(data) {
    return BaseAPI("post", `/Registration/ReadRegistrationOuter`, data);
  },
  sendRegistration(data) {
    return BaseAPI("post", `/Registration/SendRegistration`, data);
  },
  sendRegistrationOuter(data) {
    return BaseAPI("post", `/Registration/SendRegistrationOuter`, data);
  },

  // 外堂現場報名
  getRegistrationOuterOnSite(data) {
    return BaseAPI("post", `/Registration/ReadRegistrationOuterOnSite`, data);
  },
};
export default mainAPI;
